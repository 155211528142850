<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="check_date" label="检查日期">
            <a-date-picker v-model="form.others.check_date" format="YYYY-MM-DD" style="width: 100%" @change="chooseDate" />
          </a-form-model-item>
          <a-form-model-item prop="control_date" label="管控日期范围">
            <a-range-picker v-model="form.others.control_date" />
          </a-form-model-item>
          <a-form-model-item prop="record_code" label="记录编号">
            <a-input v-model="form.others.record_code" />
          </a-form-model-item>
          <a-form-model-item prop="review" label="上周回顾">
            <a-textarea v-model="form.others.review" />
          </a-form-model-item>
          <a-form-model-item prop="troubleshooting" label="本周排查">
            <a-textarea v-model="form.others.troubleshooting" />
          </a-form-model-item>
          <a-form-model-item prop="measures" label="整改措施">
            <a-textarea v-model="form.others.measures" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-textarea v-model="form.others.remark" />
          </a-form-model-item>
          <a-form-model-item prop="participants" label="参加人员">
            <a-input v-model="form.others.participants" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { weekControlAdd, weekControlEdit } from "@/api/control";

export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        check_date: [
          { required: true, message: "请选择检查日期", trigger: "change" }
        ]
      },
      loading: false,
    };
  },
  methods: {
    chooseDate(data, dateString) {
      this.form.others.check_date = dateString
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? weekControlEdit : weekControlAdd;
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      if(!this.form.id) {
        this.$refs.form.resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
